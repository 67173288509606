export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Program',
    dataIndex: 'code',
  },
  {
    title: 'Tanggal',
    dataIndex: 'date',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'vendor',
  },
  {
    title: 'Poin',
    dataIndex: 'point',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  // },
]
